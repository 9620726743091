export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

const protectedPaths = ["/", "/home", "/invalid"];
export function isProtectedURL(url) {
  let strippedExtension = url.replace(/\.[^/.]+$/, "");
  return protectedPaths.includes(strippedExtension);
}
