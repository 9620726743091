import {
  getAuth,
  EmailAuthProvider,
  PhoneAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

import * as firebaseui from "firebaseui";

import { isProtectedURL } from "./universal.js";

export async function config(loginElementTag, loginButton, auth) {
  // Does the user need to get somewhere?
  // (Either /go or direct entry)
  let wantsRedirect = !isProtectedURL(window.location.pathname);

  // FirebaseUI config
  const uiConfig = {
    credentialHelper: firebaseui.auth.CredentialHelper.NONE,
    signInOptions: [
      // Email / Password Provider.
      {
        provider: EmailAuthProvider.PROVIDER_ID,
        // signInMethod: EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
        requireDisplayName: false,
      },

      // Phone, requires ReCaptcha
      // https://firebase.google.com/docs/auth/web/firebaseui#phone_number
      // {
      //   provider: PhoneAuthProvider.PROVIDER_ID,
      //   recaptchaParameters: {
      //     type: "image", // 'audio'
      //     size: "normal", // 'invisible' or 'compact'
      //     badge: "bottomleft", //' bottomright' or 'inline' applies to invisible.
      //   },
      // },
    ],
    callbacks: {
      signInSuccessWithAuthResult: function (authResult, redirectUrl) {
        // Handle sign-in.
        // Return false to avoid redirect.
        return false;
      },
    },
  };

  const ui = new firebaseui.auth.AuthUI(auth);

  const isSignedIn = auth.currentUser != undefined;
  // For email link authentication callback.
  // https://firebase.google.com/docs/auth/web/firebaseui#email_link_authentication
  // Or, if we need to log in to access a url
  if (ui.isPendingRedirect()) {
    ui.start(loginElementTag, uiConfig);
  }
  // Listen to RSVP button clicks
  loginButton.addEventListener("click", () => {
    if (auth.currentUser) {
      // User is signed in; allows user to sign out
      signOut(auth);
    } else {
      // No user is signed in; allows user to sign in
      ui.start(loginElementTag, uiConfig);
    }
  });
  console.log("added login event listener");

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // UI Changes
      loginButton.textContent = "Log out";
      // if (wantsRedirect) {
      //   // window.location.reload();
      // }
    } else {
      loginButton.textContent = "Log in";

      if (wantsRedirect) {
        console.log(
          "User wants redirect, but is logged out. Showing login ui."
        );
        ui.start(loginElementTag, uiConfig);
      }
    }
  });
}
