// An interface for working with firebase.
import * as universal from "./universal.js";

// Firebase App (the core Firebase SDK) is always required and must be listed first
import { initializeApp } from "firebase/app";

// Add the Firebase products and methods that you want to use
import {
  getAuth,
  EmailAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

import {
  getFirestore,
  addDoc,
  collection,
  query,
  orderBy,
  onSnapshot,
  doc,
  setDoc,
  updateDoc,
  arrayUnion,
  where,
  deleteDoc,
} from "firebase/firestore";

import * as firebaseui from "firebaseui";

let db;
export let auth;
export async function mainWithUpdate(updateAction) {
  dataUpdateAction = updateAction;
  await main();
}
export async function main() {
  // Add Firebase project configuration object here
  const firebaseConfig = {
    apiKey: "AIzaSyC5X-T07IId7Dc94HoGBXC9YYj8GNsnwKs",
    authDomain: "sid-crisp.firebaseapp.com",
    projectId: "sid-crisp",
    storageBucket: "sid-crisp.appspot.com",
    messagingSenderId: "819646082770",
    appId: "1:819646082770:web:b1ffa8d6d763890eeac70b",
    measurementId: "G-D24326RCER",
  };

  // Make sure Firebase is initilized
  try {
    if (firebaseConfig && firebaseConfig.apiKey) {
      initializeApp(firebaseConfig);
    }
    db = getFirestore();
    auth = getAuth();
  } catch (e) {
    console.warn("Firebase Init Error", e);
  }

  // Listen to the current Auth state
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // console.log("Displaying logged in page");

      userRef = doc(db, "redirects", auth.currentUser.uid);
      unsubscribeUserChanges = onSnapshot(userRef, onDataUpdate);
      console.log("logged in!");
    } else {
      userRef = null;
      if (unsubscribeUserChanges !== undefined) {
        unsubscribeUserChanges();
      }
    }
    console.log("Auth state changed", auth, userRef);
    // shouldRedirect();
  });
}
// in firebase: redirects/[document with name of user ID]
let userRef;

// Dict-like object of redirects
let redirects = {};

// Watch for changes
let unsubscribeUserChanges;

export var dataUpdateAction = (urls) => {
  // console.log("Default data update action", urls);
};
function onDataUpdate(doc) {
  // console.log("ONDATAUPDATE");
  let data = doc.data();
  if (data === undefined) {
    console.warn("User data doesn't exist.", doc);
    // Doesn't return because we still want to run shouldRedirect() even if there is no database -- may create first entry!
    // return;
  } else {
    let urls = data.urls;

    // update redirects Dict
    redirects = {};
    urls.forEach((e) => {
      redirects[e.shortURL] = e.longURL;
    });
    dataUpdateAction(urls);

    // console.log("Redirects", redirects);
  }
  processLocation();
}

// Redirect if necessary
// Create URL/other actions based on current window.location.
async function processLocation() {
  console.group("Should Redirect?");

  var path = window.location.pathname.substring(1); // Substring(1) removes / that would be at beginning of path

  // Sets path based on `?code=[cde]` in url.
  const urlParams = new URLSearchParams(window.location.search);
  let code = urlParams.get("code");
  if (universal.isValidHttpUrl(code) && code != "") {
    let longURL = code;
    // Create new code from url!

    // Look for if already exists

    // TODO: Always returns undefined.
    // This is suspected beacuse when
    let shortCode = Object.keys(redirects).find(
      (key) => redirects[key] == longURL
    );
    // console.log("ShortCode key", shortCode, redirects);
    if (shortCode == undefined) {
      // Create new short if doesn't already exist
      await createURL(longURL).then(() => {
        window.location.replace("/home.html");
      });
      console.log("Created code based on longURL.", longURL);
    } else {
      console.log("Attemping to create URL that already has a short code.");
      // When this wasn't commented out, it would redirect before the URL could be created.
      //window.location.replace(`/invalid?error=URLAlreadyExists&url=${longURL}`);
    }
    // console.log("Redirecting to home.html");
    console.groupEnd();
    return;
  }
  if (code != undefined) {
    // If there's a code, overrides current raw path
    path = code;
  }

  let newURL = redirects[path];
  console.log("Should redirect?", path, code, newURL);
  if (path == "") {
    return;
  }

  if (newURL) {
    console.log("Redirecting!", newURL);
    window.location.replace(newURL); // Window.location.replace simulates an HTTP redirect. The original location does not appear in browser search history.
  } else if (
    path != undefined &&
    !universal.isProtectedURL(window.location.pathname)
  ) {
    // invalid short code, and not a protected url
    console.log("Invalid code, redirecting", code);
    var search = window.location.search;
    if (search == "") {
      // Direct short code that's nonexistent, such as site.com/123, doesn't have url parameters ready to pass. We have to add them.
      search = `?code=${path}`;
    }

    const user = getAuth().currentUser;
    if (user) {
      // Only invalid if you're logged in! Otherwise, they may just need to be prompted to log in.
      window.location.replace("/invalid?error=NotFound" + search); // assign() keeps the original location in browser history.
      // location.search appends only the URL parameters
    }
  }
  console.groupEnd();
}

// Create new entries

export async function createURL(long) {
  // Add a new message entry to the Firebase database.
  let short = generateShortURL();

  if (long == "") {
    return;
  }

  const urlItem = {
    longURL: long,
    shortURL: short,
  };

  console.log("Creating new", urlItem);

  try {
    // Appending to array:
    // https://firebase.google.com/docs/firestore/manage-data/add-data?authuser=0#update_elements_in_an_array
    await updateDoc(userRef, {
      urls: arrayUnion(urlItem),
    });

    console.log("Added new url to firebase: .", long, short);
  } catch (error) {
    // Error is possibly because there is no database item for the user at all.
    // We need to create one.

    try {
      // Create new url array for user
      console.log("Attemptinng to create new url array");
      await setDoc(userRef, {
        urls: [urlItem],
      });
    } catch (error) {
      console.error(
        "Error writing  to Firebase Database. Create new user failed.",
        error
      );
    }
  }
}

function makeid(length) {
  var result = "";
  var characters = "abcdefghijklmnopqrstuvwxyz";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function generateShortURL() {
  return makeid(3);
}

// Removing
export async function removeAllData() {
  await deleteDoc(userRef);
  console.log("All data removed.");
}
